.App {
  color: #bfbfbf;
  background-color: black;
  min-height: 100vh;
}

.App h2 {
  font-size: 1.8rem;
  line-height: 1.2;
  margin: 2rem 0 0.5rem;
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  text-align: center;
}

.App-main {
  min-height: 90vh;
  width: 80vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-link {
  color: #61dafb;
}

.App-steps {
  margin-top: 2rem;
}

.App-components {
  margin-top: 2rem;
  border: 0.75rem solid #3f3232;
  border-radius: 2rem;
  padding: 0 2rem 2rem;
  background-color: #282c34;
  width: 42rem;
  max-width: 85%;
}

.App-modal {
  max-width: 30%;
  width: fit-content;
  min-width: 18rem;
  padding: 2rem;
  margin: 5rem auto;
  background-color: black;
  color: #bfbfbf;
  font-family: 'Josefin Slab', serif;
  font-weight: bold;
  border: 1rem solid darkred;
  border-radius: 2rem;
  line-height: 1.2;
  font-size: 1.2rem;
}

.App-modal div {
  margin-bottom: 0.8rem;
}

.App-modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.8);
}

.App-components > div {
  opacity: 0.05;
}

@keyframes show {
  100% { opacity: 1; }
}

.App-components > div {
  animation: show 1s forwards;
}


/* @todo rem instead of px */
button {
  border-color: #212121;
  border-width: 5px;
  border-style: solid;
  font-size: 1.2rem;
  background-color: darkred;
  font-weight: bold;
  padding: 7px 14px;
  border-radius: 15px;
  margin: 4px;
  color: #dedede;
  letter-spacing: 1px;
}

label {
  font-size: 2rem;
  display: block;
  padding: 0.30rem;
}

input[type=checkbox] {
  background-color: black;
  display: none;
}

.fakeCheckbox {
  display: inline-block;
  width: 1.6rem;
  height: 1.6rem;
  border: 0.25rem solid darkred;
  margin-right: 1rem;
  color: red;
  font-weight: bold;
  font-size: 1.6rem;
  text-align: center;
}

.tormentHighlight {
  color: #9b0000;
}

.domPleasureHighlight {
  color: #910691;
}

.subPleasureHighlight {
  color: #5586e3;
}

.bodyPartHighlight {
  color: #ac5729;
}

.positionHighlight,
.placeHighlight
{
  color: #1e7a4e;
}

@media (max-width: 600px) {
  .App-header {
    min-height: 5vh;
  }

  .App-modal {
    max-width: 80%;
    line-height: 1.1;
    margin-top: 2rem;
    padding: 1rem 1rem;
  }

  .App-components {
    border-width: 0.25rem;
    margin-top: 1rem;
    padding: 0.7rem;
    border-radius: 1rem;
    background-color: #282c34;
  }

  .App-steps {
    margin-top: 1rem;
  }

  .App-main {
    width: 90vw;
    min-height: 95vh;
  }

  .App h2 {
    margin: 0.5rem 0 0.5rem;
    font-size: 1.4rem;
  }

  label {
    font-size: 1em;
  }

  .fakeCheckbox {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    font-size: 1rem;
    border: 0.25rem solid darkred;
    margin-right: 1rem;
    font-family: 'Roboto', sans-serif;
    border-width: 1px;
  }

  .App {
    font-size: 0.8rem;
  }

  button {
    padding: 5px 10px;
    border-width: 3px;
    font-size: 0.9rem;
  }

  .App-cant-find-options div {
    margin: 0;
  }
}